/* eslint-disable */
import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { 
    Container, 
    NavbarBrand, 
    Navbar, 
    Nav, 
    NavItem, 
    NavLink, 
    NavbarToggler, 
    Collapse, 
    UncontrolledDropdown, 
    DropdownToggle, 
    DropdownMenu, 
    DropdownItem 
} from 'reactstrap';

import logo from '../../assets/images/logos/white-text.png';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    /*--------------------------------------------------------------------------------*/
    /*To open NAVBAR in MOBILE VIEW                                                   */
    /*--------------------------------------------------------------------------------*/

    return (
        <div className="topbar" id="top">
            <div className="header6">
                <Container className="po-relative">
                <Navbar className="navbar-expand-lg h6-nav-bar">
    <NavbarBrand href="/">
        <img src={logo} alt="wrapkit" />
    </NavbarBrand>

    <NavbarToggler onClick={toggle}>
        <span className="ti-menu"></span>
    </NavbarToggler>
    <Collapse isOpen={isOpen} navbar className="hover-dropdown font-14 justify-content-end" id="h6-info">
        <Nav navbar className="ms-auto">
            <NavItem className="active">
                <Link className="nav-link" to={"/"}>Home</Link>
            </NavItem>
            <NavItem>
                <Link className="nav-link" to={"/#"}>About Us</Link>
            </NavItem>
            <NavItem>
                <Link className="nav-link" to={"/#"}>Contact Us</Link>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav>
                    Services <i className="fa fa-angle-down m-l-5"></i>
                </DropdownToggle>
                <DropdownMenu className="b-none animated fadeInUp">
                    <DropdownItem>
                        <Link className="dropdown-item" to={"/#"}>Data Recovery</Link>
                    </DropdownItem>
                    <DropdownItem>
                        <Link className="dropdown-item" to={"/#"}>Remote Support</Link>
                    </DropdownItem>
                    <DropdownItem>
                        <Link className="dropdown-item" to={"/#"}>System Maintenance</Link>
                    </DropdownItem>
                    <DropdownItem>
                        <Link className="dropdown-item" to={"/#"}>Virus and Malware Removal</Link>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                        <Link className="dropdown-item" to={"/#"}>Others</Link>
                    </DropdownItem>
                    </DropdownMenu>
                    </UncontrolledDropdown>
                    <NavItem>
                        <Link className="nav-link" to={"/#"}>Downloads</Link>
                    </NavItem>
                        </Nav>
                        <div className="act-buttons">
                            <Link to="https://api.whatsapp.com/send/?phone=447365881164&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer" className="btn btn-success-gradiant font-14">Get Expert Advice</Link>
                        </div>
                    </Collapse>
                </Navbar>
                </Container>
            </div>
        </div>
    );

}
export default Header;
